import { Title, Text, Container } from '@mantine/core';
import classes from '../styles/Maintenance.module.css';

export function MaintenancePage() {
  return (
    <Container className={classes.root}>
      <div className={classes.label}>Site en maintenance</div>
      <Title className={classes.title}>Le site est actuellement indisponible car en maintenance.</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        Le site sera à nouveau disponible sous peu. Merci de votre compréhension.
      </Text>
    </Container>
  );
}