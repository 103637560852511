import classes from "../styles/Home.module.css";
import {Container, Image, Space, Text, Title} from "@mantine/core";
import image from "../assets/valentine-votava-psychologue.png";

export default function HeroSection() {
    return (
        <Container size="md">
            <div className={classes.inner}>
                <Image src={image} className={'h-[300px] mx-12'} />
                <div className={classes.content}>
                    <Title className={classes.title}>
                        Valentine Votava
                    </Title>
                    <Text c="dimmed">
                        Psychologue clinicienne - Psychothérapeute
                    </Text>
                    <Space h={'lg'} />
                    <Text>
                        Bienvenue dans un espace dédié à votre bien-être émotionnel. Je suis Valentine VOTAVA, psychologue clinicienne et psychothérapeute,
                        et je suis ici pour vous accompagner. Avec une approche empathique et personnalisée, je vous offre un lieu sécurisé pour explorer vos
                        pensées, sentiments et expériences. Ensemble, nous travaillerons à comprendre les défis auxquels vous faites face et à développer
                        des solutions pour favoriser votre croissance personnelle. Que vous recherchiez un soutien à court terme ou un travail plus
                        approfondi, je suis là pour vous guider vers un bien-être mental durable.
                    </Text>
                </div>
            </div>
        </Container>
    )
}