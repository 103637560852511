import logo from '../assets/logo_valentine_psychologue.png'
import { Link, useLocation } from "react-router-dom";
import PathConstants from "../routes/pathConstants";
import NavLink from "./NavLink";

export default function Header() {
    const location = useLocation()
    const path = location.pathname

    return (
        <nav className="bg-primary border-gray-200 dark:bg-gray-900 h-[80px]">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <a href="https://flowbite.com/" className="flex items-center">
                    <img src={logo} className="h-12 mr-3" alt="Votava Psychologue Logo"/>
                </a>
                <button data-collapse-toggle="navbar-default" type="button"
                        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>
                <div className="hidden w-full md:block md:w-auto mr-12" id="navbar-default">
                    <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                        <li>
                            <NavLink path={PathConstants.HOME} label={'Accueil'} />
                        </li>
                        <li>
                            <NavLink path={PathConstants.ABOUT} label={'À propos'} />
                        </li>
                        <li>
                            <NavLink path={PathConstants.CONTACT} label={'Contact'} />
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    )
}