import React from 'react';
import './App.css';
import Home from "./pages/Home";
import Layout from "./Layout";
import '@mantine/core/styles.css';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom"
import Contact from "./pages/Contact";
import About from "./pages/About";
import { inject } from '@vercel/analytics';
import { MaintenancePage } from './pages/Maintenance';
import { MantineProvider } from '@mantine/core';

function App() {
    const isUnderMaintenance = Number(process.env.MAINTENANCE_MODE ?? 1)

    inject();
    const router = createBrowserRouter([
        {
            // parent route component
            element: <Layout />,
            // child route components
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                {
                    path: "/about",
                    element: <About />,
                },
                {
                    path: "/contact",
                    element: <Contact />,
                },
                {
                    path: "/maintenance",
                    element: <MaintenancePage />,
                }
            ],
        },
    ])

  return (
    <>
        {isUnderMaintenance === 1 ? 
            <MantineProvider><MaintenancePage /></MantineProvider> :
            <RouterProvider router={router} />
        }
  </>
  )
}

export default App;
