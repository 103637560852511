import {Anchor, Group, ActionIcon, rem, Stack} from '@mantine/core';
import {IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconLocation, IconPhone} from '@tabler/icons-react';
import classes from '../styles/Footer.module.css';
import logo from '../assets/logo_valentine_psychologue.png'

const links = [
    { link: '/', label: 'Accueil' },
    { link: '/about', label: 'À propos' },
    { link: '/contact', label: 'Contact' },
];

export function Footer() {
    const items = links.map((link) => (
        <Anchor
            c="dimmed"
            key={link.label}
            href={link.link}
            lh={1}
            onClick={(event) => event.preventDefault()}
            size="sm"
        >
            {link.label}
        </Anchor>
    ));

    return (
        <div className='bg-primary h-[80px]'>
            <div className={classes.inner}>
                <Stack gap={5}>
                    <Group className={'text-xs'} gap={5}>
                        <IconLocation style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                        5 boulevard Ampère (Bâtiment C) 44470 CARQUEFOU
                    </Group>
                    <Group className={'text-xs'} gap={5}>
                        <IconPhone style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                        06 65 14 92 39
                    </Group>
                </Stack>

                <Group className={'text-xs'}>© Copyright Valentine VOTAVA - 2023</Group>

                {/* <Group gap="xs" justify="flex-end" wrap="nowrap">
                    <ActionIcon size="lg" variant="default" radius="xl">
                        <IconBrandTwitter style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                    </ActionIcon>
                    <ActionIcon size="lg" variant="default" radius="xl">
                        <IconBrandYoutube style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                    </ActionIcon>
                    <ActionIcon size="lg" variant="default" radius="xl">
                        <IconBrandInstagram style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                    </ActionIcon>
                </Group> */}
            </div>
        </div>
    );
}