import Header from "./components/Header";
import {Footer} from "./components/Footer";
import {createTheme, MantineProvider} from "@mantine/core";
import {ContactSection} from "./components/ContactSection";
import { Outlet } from "react-router-dom"

interface LayoutProps {
    children?: React.ReactNode
}

const theme = createTheme({
    /** Put your mantine theme override here */
    colors: {
        primary: [
            "#fdf6ea",
            "#f3eadc",
            "#e5d4b8",
            "#d6bc90",
            "#caa86e",
            "#c29b59",
            "#c0954c",
            "#a8813d",
            "#967233",
            "#826226"
        ]
    },
});

export default function Layout({children}: LayoutProps) {
    return (
        <MantineProvider theme={theme}>
            <div className={'min-h-screen'}>
                <Header />
                <main className={'bg-secondary h-full'}>
                    <Outlet />
                </main>
                <Footer />
            </div>
        </MantineProvider>
    )
}