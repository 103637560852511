import {
    Text,
    Title,
    SimpleGrid,
    TextInput,
    Textarea,
    Button,
    Group,
    ActionIcon,
} from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons-react';
import { ContactIconsList } from './ContactIcons';
import classes from '../styles/ContactSection.module.css';
import { useForm } from '@mantine/form';
import { sendContactMail } from '../lib/resend';

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

export function ContactSection() {

    const form = useForm({
        initialValues: {
          email: '',
          lastname: '',
          firstname: '',
          message: ''
        },
    
        validate: {
          email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Adresse mail invalide'),
          lastname: (value) => value?.length > 0 ? null : 'Vous devez saisir votre nom',
          firstname: (value) => value?.length > 0 ? null : 'Vous devez saisir votre prénom',
          message: (value) => value?.length > 0 ? null : 'Vous devez saisir un message'
        },
      });

    const icons = social.map((Icon, index) => (
        <ActionIcon key={index} size={28} className={classes.social} variant="transparent">
            <Icon size="1.4rem" stroke={1.5} />
        </ActionIcon>
    ));

    return (
        <div className={classes.wrapper}>
            <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={50}>
                <div>
                    <Title className={classes.title}>Me contacter</Title>
                    <Text className={classes.description} mt="sm" mb={30}>
                        Laissez moi un message, je reviendrai vers vous le plus rapidement possible
                    </Text>

                    <ContactIconsList />

                    {/* <Group mt="xl">{icons}</Group> */}
                </div>
                <div className={classes.form}>
                    <form onSubmit={form.onSubmit((values) => sendContactMail(values))}>
                        <TextInput
                            required
                            label="Email"
                            placeholder="votre@email.com"
                            classNames={{ input: classes.input, label: classes.inputLabel }}
                            {...form.getInputProps('email')}
                        />
                        <TextInput
                            required
                            label="Nom"
                            placeholder="VOTAVA"
                            mt="md"
                            classNames={{ input: classes.input, label: classes.inputLabel }}
                            {...form.getInputProps('lastname')}
                        />
                        <TextInput
                            required
                            label="Prénom"
                            placeholder="Valentine  "
                            mt="md"
                            classNames={{ input: classes.input, label: classes.inputLabel }}
                            {...form.getInputProps('firstname')}
                        />
                        <Textarea
                            required
                            label="Votre message"
                            placeholder="J'aimerais prendre rendez-vous..."
                            minRows={4}
                            mt="md"
                            classNames={{ input: classes.input, label: classes.inputLabel }}
                            {...form.getInputProps('message')}
                        />

                        <Group justify="flex-end" mt="md">
                            <Button className={classes.control} type='submit'>Envoyer</Button>
                        </Group>
                    </form>
                </div>
            </SimpleGrid>
        </div>
    );
}